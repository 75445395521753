<ui-modal></ui-modal>

<ng-container *ngIf="this.isLoaded && !this.isLanding && !this.isStoreLocatorWidget">
  <header *ngIf="!(isWidget$ | async)" [ngClass]="{'sticky-header': menuOpen}">
    <app-top-bar></app-top-bar>
    <app-nav-bar></app-nav-bar>
  </header>

    <router-outlet></router-outlet>

  <footer *ngIf="!(isWidget$ | async)">
    <app-footer-bar></app-footer-bar>
    <app-bottom-bar></app-bottom-bar>
  </footer>
</ng-container>

<ng-container *ngIf="isLanding || isStoreLocatorWidget">
  <router-outlet></router-outlet>
</ng-container>

<app-page-loader></app-page-loader>

