import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {ProjectService} from '../../services/project.service';
import {Extras} from '../../models/extras.enum';
import {IRemoteProfile} from '../../interfaces/iremote-profile';
import {PagesService} from '../../services/pages.service';
import {WebsiteConfigService} from '../../services/website-config.service';
import {CmsService} from 'src/app/modules/cms/services/cms.service';
import {takeUntil, map} from 'rxjs/operators';
import {StoreLocatorPageService} from 'src/app/modules/pages/store-locator-page/store-locator-page.service';
import {Networks} from '../../models/networks.enum';
import { MobileService } from '../../services/mobile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  menuBtn ;
  menuOpen = false;
  isCollapsed = false;
  isNetworkSite = false;
  isWTStore = false;
  isWTShop = false;
  isWtgNetworkSite = false; // sito network WTG

  private unsubscribe$ = new Subject<any>();

  profile$: Observable<IRemoteProfile>;
  hasServices$: Observable<boolean>;
  //links$: Observable<any>;
  customLinks: any[];
  hasNewsletter: boolean;
  hasListe: boolean;
  hasMultipleStores: boolean = false;
  configDetailsFromProject: any[] = [];
  isEnabledListeInViaggioLegacy: boolean = false;
  listeInViaggioUrl: string = '';
  listeInViaggioLabel: string = '';

  public brand: any;
  public networks = Networks;
  public isMobile$: Observable<boolean>;

  public hasPropostePage: boolean;
  public hasConad: boolean;
  public isBCV: boolean = false;

  public deactivateDropdownForSpecificAgencies: string[] = ['26557']
  public showDropdownContentInNavlinkLevel: boolean = false;

  private getStoresSubscription: Subscription;

  constructor(
    private _router: Router,
    private _projectService: ProjectService,
    private _pagesService: PagesService,
    private _cmsService: CmsService,
    private _websiteConfigService: WebsiteConfigService,
    private _mobileService: MobileService,
    private sanitizer:DomSanitizer,
    private _storeLocatorService: StoreLocatorPageService,
  ) {}

  ngOnInit(): void {
    window.addEventListener('touchmove', this.onTouchMove.bind(this));
    this.isMobile$ = this._mobileService.isMobile();
    this.profile$ = this._projectService.getProfile().pipe(
      map((profile) => {
        this.showDropdownContentInNavlinkLevel =  this.deactivateDropdownForSpecificAgencies.indexOf(profile.id) >= 0;
        profile.whatsapp = profile.whatsapp?.trim()?.replace(/\s/g, '');
        return profile;
      }),
    );

    this.hasServices$ = this._pagesService.hasServices();
    this._websiteConfigService.getLinks().pipe(takeUntil(this.unsubscribe$)).subscribe(links => {
      if (links) {
        this.customLinks = links;
      }
    });
    this.getStoresSubscription = this._storeLocatorService.getStores();
    this._storeLocatorService.storesList$.pipe(takeUntil(this.unsubscribe$)).subscribe(storeList => {
        this.hasMultipleStores = storeList?.length > 1;
    });

    this._projectService.getServicesFromProject().pipe(takeUntil(this.unsubscribe$)).subscribe(e => {
      if (e) {
        e.forEach(x => {
          if (x.service_code === Extras.NEWSLETTER_B2C_WELCOME_TRAVEL || x.service_code === Extras.NEWSLETTER_B2C_CRM_GEO || x.service_code === Extras.NEWSLETTER_B2C) {
            this.hasNewsletter = true;
          } else if (x.service_code === Extras.SERVIZI_DIGITALI_LISTE_IN_VIAGGIO) {
            this.hasListe = true;
          }
        });
      }
    });

    this._cmsService.brandConfig$.pipe(takeUntil(this.unsubscribe$)).subscribe(brand => {
      if (brand) {
        this.brand = brand;
      }
    });

    this._cmsService.siteUrl$.pipe(takeUntil(this.unsubscribe$)).subscribe(siteUrl => {
      if (siteUrl) {
        if (siteUrl.includes('geotn.it')) {
          this.isNetworkSite = true;
        }
        if (siteUrl.includes('wtstore.it')) {
          this.isWTStore = true;
        }
        if (siteUrl.includes('welcometravelshop.it')) {
          this.isWTShop = true;
        }
        if (siteUrl.includes('vacanzewelcometravel.it')) {
          this.isWtgNetworkSite = true;
        }
      }
      this.initConfigDetailsFromProject();
    });

    this._cmsService.hasPropostePage$.pipe(takeUntil(this.unsubscribe$)).subscribe(hasProposte => {
      this.hasPropostePage = hasProposte;
    });
    this._cmsService.hasConad$.pipe(takeUntil(this.unsubscribe$)).subscribe(hasConad => {
      this.hasConad = hasConad;
    });

    this._storeLocatorService.isBCV$.pipe(takeUntil(this.unsubscribe$)).subscribe(isBusinessClassViaggi => {
      this.isBCV = isBusinessClassViaggi;
    })
  }

  public sanitize(url: string) : any{
    return (url) ? this.sanitizer.bypassSecurityTrustUrl(url) : '';
  }

  ngOnDestroy(): void {
    this.getStoresSubscription?.unsubscribe();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    window.removeEventListener('touchmove', this.onTouchMove.bind(this));
  }

  private getConfigByIdFromConfigDetails(configId: string): any {
    return (this.configDetailsFromProject?.length) ?
      this.configDetailsFromProject?.find((config) => config.id?.includes(configId)) :
      null;
  }

  private initConfigDetailsFromProject(): void {
    this._projectService.getConfigDetailsFromProject()
      .toPromise()
      .then((configDetail) => {
        if (configDetail || this.isWtgNetworkSite) {
          // salvo tutto l'oggetto del details contenente le varie configurazioni del sito (tab "WTG" del modulo legacy Gestione Siti Web di zeus)
          this.configDetailsFromProject = configDetail;

          // verifico se ho abilitato le listeinviaggio dalla config
          // NB: se si tratta del sito vacanzewelcometravel/istituzionale wtg, allora abilito a prescindere le listeinviaggio
          this.isEnabledListeInViaggioLegacy = this.getConfigByIdFromConfigDetails('listeinviaggio')?.value || this.isWtgNetworkSite;

          /*// se le listeinviaggio non sono abilitate, allora salto il recupero dell'url
          if (!this.isEnabledListeInViaggioLegacy) {
            return null;
          }
*/
          this.listeInViaggioLabel = this.getConfigByIdFromConfigDetails('listeinviaggiolabel')?.value || 'Liste Eventi';

          const isListeInViaggioCustom = this.getConfigByIdFromConfigDetails('listeinviaggiocustom')?.value;
          const listeInViaggioExternalUrl = this.getConfigByIdFromConfigDetails('listeinviaggioexternal')?.value;

          // se ho impostato un url di listeinviaggio esterno, utilizzo questo
          if (listeInViaggioExternalUrl) {
            this.listeInViaggioUrl = listeInViaggioExternalUrl;

            return null;
          }

          // ... altrimenti, recupero l'url di listeinviaggio di wtg (se custom, viene concatenato il codice agenzia)
          return this._projectService.getWtgOrGeoListeInViaggioUrl(isListeInViaggioCustom).toPromise();
        }
        return this._projectService.getWtgOrGeoListeInViaggioUrl(false).toPromise();
      })
      .then((wtgListeInViaggioUrl) => {
        if (wtgListeInViaggioUrl) {
          this.listeInViaggioUrl = wtgListeInViaggioUrl;
        }
      })
      .catch(err => console.info(err));
  }

  public goHome(): void {
    this.isCollapsed = false;
    this._router.navigate(['']);
  }

  //logica side bar button versione mobile

  @ViewChild('navbar', { static: true }) navbarElement!: ElementRef;

  toggle(){
    this.isCollapsed = !this.isCollapsed
    this.menuBtn = document.querySelector('.menu-btn')
    const navbarMainNavDropdown = document.getElementById('navbarMainNavDropdown');

    if(!this.menuOpen) {
      this.menuBtn.classList.add('open');
      this.menuOpen = true;
      this._mobileService.updateSidebarToggle(true)
    } else {
      this.menuBtn.classList.remove('open');
      this.menuOpen = false;
      navbarMainNavDropdown.classList.add('sideBarOut')
      navbarMainNavDropdown.addEventListener('animationend', () => {
        navbarMainNavDropdown.classList.remove('sideBarOut')
      }, {once:true})
      this._mobileService.updateSidebarToggle(false)
    }

  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (!this.navbarElement.nativeElement.contains(target)) {
      if(this.menuOpen){
        this.toggle()
      }
    }
  }

  onTouchMove(event: TouchEvent) {
    const target = event.target as HTMLElement;

    if (!this.navbarElement.nativeElement.contains(target)) {
      if(this.menuOpen){
        this.toggle()
      }
    }
  }

}
